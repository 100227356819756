import React from "react"
import styled from "styled-components"

const CenterDiv = styled.div`
    display: table;
    margin: 2em auto;
    font-size: 5em;
    font-family: Lato, sans-serif;
`


export default () => {
    return ( 
        <CenterDiv>
            erobl.xyz
        </CenterDiv>
    )

}
