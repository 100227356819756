import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PaddedLink = styled.a`
    padding: 0pt 12pt;
`

const MailDiv = styled.div`
    font-size: 14pt;
`

export default () => {
    return <div style={{fontSize: "30pt", paddingBottom: "1em"}}>
        <div style={{ textAlign: "center" }}>
            <div>
                <Link to="/">Edgar Robles</Link>
            </div>
            <PaddedLink href="http://github.com/erobl/">
                <FontAwesomeIcon icon={["fab", "github"]} />
            </PaddedLink>
            <MailDiv>
                roblesee🤖uci.edu
            </MailDiv>
        </div>
    </div>
}