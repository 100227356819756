import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

const PubBox = styled.div`
  background: #dbdbdb;
  margin: 1em;
  padding: 1em;
  border-radius: 0.5em;
`

const Title = styled.div`
  font-weight: bold
`

const Date = styled.div`
    font-size: 10pt;
    color: #828282;
`

const Authors = styled.div`
    font-size: 10pt;
    color: #828282;
`

const PublicationContainer = (edge) => {
  console.log(edge.node)
  return (
  <PubBox>
    <Title>
      {edge.node.title}
    </Title>
    <Date>
      {edge.node.date}
    </Date>
    <Authors>
      {edge.node.authors.join(", ")}
    </Authors>
  </PubBox>
  )
}

export default ({data}) => {
    return (
      <StaticQuery
        query={
          graphql`
            query {
                allReference {
                  edges {
                    node {
                      title
                      authors
                      date
                    }
                  }
                }
              }  
            `
        }

        render={
          data => {
            return data.allReference.edges.map(PublicationContainer)
          }
        }
      />
    )
}
