import React from "react"
import Link from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import styled from "styled-components"

const Title = styled.div`
    font-size: 12pt;
    weight: bold;
`

const Date = styled.div`
    font-size: 10pt;
    color: #828282;
`

const Detail = styled.div`
    font-size: 10pt;
`

const CircleDiv = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
`

const TimelineElem = (props) => {
    return (
        <TimelineItem>
            <TimelineSeparator>
                {props.first ? null : <TimelineConnector />}
                <TimelineDot>
                    <CircleDiv>
                        {props.image}
                    </CircleDiv>
                </TimelineDot>
                {props.last ? null : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Title>
                    {props.title}
                </Title>
                <Date>
                    {props.date}
                </Date>
                <Detail>
                    {props.detail}
                </Detail>
            </TimelineContent>
        </TimelineItem>
    )
}

export default () => (
    <Timeline>
    <TimelineElem 
        image={
            <StaticImage 
                src="../images/uci.png" 
                alt="UCI" 
                width={30}
                height={30}
                layout="fixed"
            />
        }
        title="UCI"
        date="2020 - Present"
        detail="Research Assistant"
        first
    />
    <TimelineElem 
        image={
            <StaticImage 
                src="../images/symbiotic.svg" 
                alt="Symbiotic" 
                width={30}
                height={30}
                layout="fixed"
                transformOptions={{fit: "inside"}}
            />
        }
        title="Symbiotic"
        date="2019 - 2020"
        detail="Software Developer"
    />
    <TimelineElem 
        image={
            <StaticImage 
                src="../images/uned.png" 
                alt="National University of Distance Education" 
                width={30}
                height={30}
                layout="fixed"
                transformOptions={{fit: "inside"}}
            />
        }
        title="National University of Distance Education"
        date="2019"
        detail="Researcher"
    />
    <TimelineElem 
        image={
            <StaticImage 
                src="../images/ipam.png" 
                alt="IPAM" 
                width={30}
                height={30}
                layout="fixed"
                transformOptions={{fit: "inside"}}
            />
        }
        title="Institute of Pure and Applied Mathematics at UCLA"
        date="2018"
        detail="Student Researcher"
    />
    <TimelineElem
        image={
            <StaticImage 
                src="../images/ucr.png" 
                alt="University of Costa Rica" 
                width={30}
                height={30}
                layout="fixed"
            />
        }
        title="University of Costa Rica"
        date="2015 - 2019"
        detail="Research Assistant"
        last
    />
  </Timeline>
)