import React from "react"
import Layout from "../components/layout.js"
import Block from "../components/Block.js"
import SplashScreen from "../components/splashscreen.js"
import AboutMe from "../contents/about-me.js"
import Publications from "../contents/publications.js"
import Experience from "../contents/experience.js"
import Education from "../contents/education.js"
import { Helmet } from "react-helmet"

export default () => (
<Layout>
    <Helmet>
        <title>Edgar Robles</title>
    </Helmet>
    <Block title="About Me" even={false} anchor="about-me">
        <AboutMe />
    </Block>
    <Block title="Experience" even={true} anchor="Experience">
        <Experience />
    </Block>
    <Block title="Education" even={false} anchor="education">
        <Education />
    </Block>
    <Block title="Publications" even={true} anchor="publications">
        <Publications />
    </Block>
</Layout>
)
