import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"
import Bio from "./Bio.js"

const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #383C41;
    }

    font-size: 16pt;
`

const StyledAnchorLink = styled(AnchorLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: #383C41;
    }

    font-size: 16pt;
`

const SidebarLink = (props) => {
    return (
    <div style={{padding: "1em 0em"}}>
        <StyledLink to={props.to}>{props.children}</StyledLink>
    </div>
    )
}

const SidebarAnchorLink = (props) => {
    return (
    <div style={{padding: "1em 0em"}}>
        <StyledAnchorLink to={props.to}>{props.children}</StyledAnchorLink>
    </div>
    )
}


const Content = styled.div`
    padding: 1em 2em;
    font-family: Lato, sans-serif
`

const BioContainer = styled.div`
    @media (max-width: 800px) {
        display: none;
    }
`

export default (props) => {
    return (
        <Content>
            <BioContainer>
                <Bio /> 
            </BioContainer>
            <SidebarAnchorLink to="/#about-me">About Me</SidebarAnchorLink>
            <SidebarAnchorLink to="/#experience">Experience</SidebarAnchorLink>
            <SidebarAnchorLink to="/#education">Education</SidebarAnchorLink>
            <SidebarAnchorLink to="/#publications">Publications</SidebarAnchorLink>
        </Content>
    )
}
