import React, { Component } from "react";
import Sidebar from "./sidebar/Sidebar.js";
import "./layout.css";
import styled from "styled-components";
import SidebarContents from "./sidebar/SidebarContents";
import Bio from "./sidebar/Bio.js";
import Block from "./Block.js"

const BioContainer = styled.div`
    @media (min-width: 800px) {
        display: none;
    }
`

export default class Layout extends Component {
    render() {
        return (
            <Sidebar sidebar={<SidebarContents />}>
                <BioContainer>
                    <Block even={true}>
                        <Bio />
                    </Block>
                </BioContainer>
                {this.props.children}
            </Sidebar>
        );
    }
}
