import React from "react"
import Link from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import styled from "styled-components"

const Title = styled.div`
    font-size: 12pt;
    weight: bold;
`

const Date = styled.div`
    font-size: 10pt;
    color: #828282;
`

const Detail = styled.div`
    font-size: 10pt;
`

const TimelineElem = (props) => {
    return (
        <TimelineItem>
            <TimelineSeparator>
                {props.first ? null : <TimelineConnector />}
                <TimelineDot>
                    {props.image}
                </TimelineDot>
                {props.last ? null : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Title>
                    {props.title}
                </Title>
                <Date>
                    {props.date}
                </Date>
                <Detail>
                    {props.detail}
                </Detail>
            </TimelineContent>
        </TimelineItem>
    )
}

export default () => (
    <Timeline>
    <TimelineElem 
        image={
            <StaticImage 
                src="../images/uci.png" 
                alt="University of California, Irvine" 
                width={30}
                height={30}
                layout="fixed"
            />
        }
        title="University of California, Irvine"
        date="2020 - Present"
        detail="PhD, Computer Science"
        first
    />
    <TimelineElem
        image={
            <StaticImage 
                src="../images/ucr.png" 
                alt="University of Costa Rica" 
                width={30}
                height={30}
                layout="fixed"
            />
        }
        title="University of Costa Rica"
        date="2014 - 2018"
        detail="BS, Computer Science"
        last
    />
  </Timeline>
)